import axios from "axios";
let URL_API = "https://developement.backend.maystro-delivery.com/api/"; 

let exportfromServerUrl = URL_API;

if (process.env.REACT_APP_STATE === "prod") {
  URL_API = "https://backend2.maystro-delivery.com/api/";
  exportfromServerUrl = "https://data-backend.maystro-delivery.com/api/";
}
if (process.env.REACT_APP_STATE === "costumized") {
  console.log("you are using a costumized api address!");
  URL_API = process.env.DJANGO_BACKEND;
  exportfromServerUrl = URL_API;

  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "devloc") {
  console.log("you are using a costumized api address!");
  URL_API = process.env.DJANGO_BACKEND;
  exportfromServerUrl = URL_API;

  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "dev") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://backenddev.maystro-delivery.com/api/";
  exportfromServerUrl = URL_API;

  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "dev2") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://maystro-backend-nyg42cuu7q-ew.a.run.app/api/";
  exportfromServerUrl = URL_API;

  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "dev-new") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://md-shop-variants-nyg42cuu7q-lm.a.run.app/api/";
  exportfromServerUrl = URL_API;

  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "dev-shop") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://md-shop-variants-nyg42cuu7q-lm.a.run.app/api/";
  exportfromServerUrl = URL_API;

  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "developement") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://developement.backend.maystro-delivery.com/api/";
  exportfromServerUrl = URL_API;

  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "pre-prod") {
  console.error(
    "you are using the pre-prod server! be sure no one else is using it for testing!"
  );
  URL_API = "https://backend-preprod.maystro-delivery.com/api/";
  exportfromServerUrl = URL_API;
  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "staging") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://backend-staging-utvw6oxohq-ew.a.run.app/api/";
  exportfromServerUrl = URL_API;

  console.log("URL API IS:", URL_API);
}
if (process.env.REACT_APP_STATE === "dev-loc") {
  console.error("you are using the local server!");
  URL_API = "http://localhost:8000/api/";
  exportfromServerUrl = URL_API;

  console.log("URL API IS:", URL_API);
}

export const RequestService = {
  GetRequest,
  GetAllRequest,
  PostRequest,
  PatchRequest,
  DeleteRequest,
  PutRequest,
};

function GetRequest(url, headers) {
  return axios.get(`${URL_API}${url}`, {
    headers: headers,
  });
}

function GetAllRequest(urls, headers) {
  return axios.all(
    urls.map((url) =>
      axios.get(`${URL_API}${url}`, {
        headers: headers,
      })
    )
  );
}

function PostRequest(url, data, headers, addons) {
  const onUploadProgress = addons?.onUploadProgress;
  const responseType = addons?.responseType;
  const isExportFromServer = addons?.isExportFromServer;
  let reqConfig = {
    headers,
  };
  if (onUploadProgress) reqConfig = { ...reqConfig, onUploadProgress };
  if (responseType) reqConfig = { ...reqConfig, responseType };
  const urlApi = isExportFromServer ? exportfromServerUrl : URL_API;
  return axios.post(`${urlApi}${url}`, data, reqConfig);
}

function PatchRequest(url, data, headers, addons) {
  const onUploadProgress = addons?.onUploadProgress;
  const responseType = addons?.responseType;
  const isExportFromServer = addons?.isExportFromServer;
  let reqConfig = {
    headers,
  };
  if (onUploadProgress) reqConfig = { ...reqConfig, onUploadProgress };
  if (responseType) reqConfig = { ...reqConfig, responseType };
  const urlApi = isExportFromServer ? exportfromServerUrl : URL_API;
  return axios.patch(`${urlApi}${url}`, data, reqConfig);
}
function DeleteRequest(url, headers) {
  return axios.delete(`${URL_API}${url}`, {
    headers: headers,
  });
}
function PutRequest(url, data, headers) {
  return axios.put(`${URL_API}${url}`, data, {
    headers: headers,
  });
}
